import { GoogleMap, Marker, useLoadScript } from "@react-google-maps/api";
import React from "react";
import "../css/GoogleMaps.css";

const libraries = ['places'];
const mapContainerStyleSmall = {
  width: '100%',
  height: '350px',
  borderRadius: '10px',
};

const mapContainerStyleMedium = {
  width: '100%',
  height: '350px',
  borderRadius: '10px',
};

const mapContainerStyleLarge = {
  width: '100%',
  height: '508px',
  borderRadius: '10px',
};



const center = {
  lat: 1.317603, 
  lng: 103.8808206,
};



const GoogleMaps = () => {
  const [width, setWidth] = React.useState(window.innerWidth);

  React.useEffect(() => {
    const handleResizeWindow = () => setWidth(window.innerWidth);
    // subscribe to window resize event "onComponentDidMount"
    window.addEventListener("resize", handleResizeWindow);
    return () => {
      // unsubscribe "onComponentDestroy"
      window.removeEventListener("resize", handleResizeWindow);
    };
  }
  , []);

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: 'AIzaSyDqEyNt5rs8cke7JUpdZ1Vvept205vWCPc',
    libraries,
  });

  if (loadError) {
    return <div>Error loading maps</div>;
  }

  if (!isLoaded) {
    return <div>Loading maps</div>;
  }

  function removeMapText() {
    let mapText = document.getElementsByClassName("google-maps-text-container");
    mapText[0].style.display = "none";
  }

  return (
    <div className="google-maps-app">
      {!isLoaded ? (
        <></>
      ) : (
      <GoogleMap
        mapContainerStyle={width < 700 ? mapContainerStyleSmall : width < 1000 ? mapContainerStyleMedium : mapContainerStyleLarge }
        zoom={16}
        center={center}
        onClick={removeMapText}
      >
        <Marker position={center} />
        <div className="google-maps-text-container">
        <p className="google-maps-top-text">
        Visit Us
        </p>
        <p className="google-maps-bottom-text">
        Prosper House, 101 Lor 23 Geylang, Singapore 388399
        </p>
      </div>
      </GoogleMap>
      )}
     
    </div>
  );
};

export default GoogleMaps;
