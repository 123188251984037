import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';

import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Home from './pages/Home';
import AboutUs from './pages/AboutUs';
import ErrorPage from './pages/ErrorPage';
import ContactUsPage from './pages/ContactUsPage';
import ExperienceHub from './pages/ExperienceHub';
import SolutionsPage from './pages/SolutionsPage';
import PostPage from './pages/PostPage';
import AllPostsPage from './pages/AllPostsPage';
import AllProductsPageHomaLightingPage from './pages/AllProductsHomaLightingPage';
import AllProductsPageHomeControlPage from './pages/AllProductsHomeControlPage';
import WhatsappIconHover from './components/WhatsappIconHover';

import ProductPage from './pages/ProductPage';

function App() {
  return (
    <div id="main">
      <Router>
        <Navbar/>
        <Routes>
          <Route path="/" element={<Home/>}/>
          <Route path="/about" element={<AboutUs/>}/>
          <Route path="/experience-hub" element={<ExperienceHub/>}/>
          <Route path="/solutions" element={<SolutionsPage/>}/>
          <Route path="/contact-us" element={<ContactUsPage/>}/>
          <Route path="/all-posts" element={<AllPostsPage/>}/>
          <Route path="/all-posts/:slug" element={<PostPage/>}/>
          <Route path="/products-lighting" element={<AllProductsPageHomaLightingPage/>}/>
          <Route path="/products-lighting/:slug" element={<ProductPage/>}/>
          <Route path="/products-control" element={<AllProductsPageHomeControlPage/>}/>
          <Route path="/products-control/:slug" element={<ProductPage/>}/>
          <Route path="*" element={<ErrorPage/>}/>
        </Routes>
        <WhatsappIconHover />
        <Footer/>
      </Router>
    </div>
  );
}

export default App;
