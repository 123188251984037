import React from "react";

import { FaWhatsapp } from "react-icons/fa";
import WhatsappIcon from "../imagesNew/Whatsapp Button.png";

const WhatsappIconHover = () => {
    return (
        <div className="whatsapp-icon-hover" style={{
            display: "block",
            position: "fixed",
            bottom: "20px",
            right: "40px",
            zIndex: "1000",
            cursor: "pointer",
        }}>
            <img src={WhatsappIcon} alt="WhatsApp Icon" tabIndex={0} onClick={
                () => {
                    window.open("https://wa.me/message/7WKBLNPV3PPDL1");
                }
            }/>
        </div>
    );
}

export default WhatsappIconHover;