import React from "react";
import "../css/AllProductsPage.css";

import HomaControl from "../imagesNew/Home/Home Control - Desktop Section.png";
import HomaLighting from "../imagesNew/Home/Homa Lighting - Desktop Section.png";
import CTAImage from "../components/CTAImage";

const AllProductsPage = () => {
  const [width, setWidth] = React.useState(window.innerWidth);
  const [height2, setHeight] = React.useState(window.innerHeight);

  React.useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }
  , []);



  return (
    <div>
<div className="solutions-bottom-image-container" style={{marginBottom: "0px",
height: height2 - 80 + "px",}}>
      <div className="solutions_stretched_container" style={{
        height: height2 - 80 + "px",
      }}>
        <img
            src={HomaLighting}
            alt={`Living Room View`}
            className="solutions_img_stretched"
          />
          <div style={{
            position: "absolute",
            top: "0",
            left: "0",
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "left",
            flexDirection: "column",
          }}>        
        <p className="solutions-bottom-image-top-text" style={{
          marginTop: "0px",
        }}>SOLUTIONS</p>
        <p className="solutions-bottom-image-title">Homa Lighting</p>
        <p className="solutions-bottom-image-bottom-text">Coming Soon.</p>
      </div>
        </div>
      
      </div>

      <div
        className="solutions-top-image-container"
        style={{ marginBottom: "70px" }}
      >
        <div className="solutions_stretched_container">
          <img
            src={HomaControl}
            alt={`Living Room View`}
            className="solutions_img_stretched"
          />
        </div>
        <div style={{ position: "absolute", top: "0px" }}>
          <p className="solutions-top-image-top-text">SOLUTIONS</p>
          <p className="solutions-top-image-title">Home Control</p>
          <p className="solutions-top-image-bottom-text">
            See how smart home automation and scene automation can make your
            life easier, more convenient, and more energy-efficient. Our team of
            experts will be on hand to answer your questions and help you create
            the perfect smart home system for your needs.
          </p>
        </div>
      </div>
      <CTAImage />
    </div>
  );
};

export default AllProductsPage;
