import React from "react";
import "../css/Navbar.css";
import Logo from "../imagesNew/Homa Header Logo-01.png";
import { MdClose } from "react-icons/md";
import { FiMenu } from "react-icons/fi";
import { FaAngleDown } from "react-icons/fa";
import { useState, useRef, useEffect } from "react";

import HomaLightingIcon from "../imagesNew/Navbar/Homa Lighting Icon.png";
import SmartHomeIcon from "../imagesNew/Navbar/Smart Home Icon.png";
import AboutUsIcon from "../imagesNew/Navbar/About Us icon.png";
import SolutionsIcon from "../imagesNew/Navbar/Solutions Icon.png";
import ArticlesIcon from "../imagesNew/Navbar/Articles Icon.png";

const Navbar = () => {
  const [width, setWidth] = React.useState(window.innerWidth);
  const [navbarOpen, setNavbarOpen] = useState(false);

  React.useEffect(() => {
    const handleResizeWindow = () => setWidth(window.innerWidth);
    if (width > 1000) {
      setNavbarOpen(false);
    }
    // subscribe to window resize event "onComponentDidMount"
    window.addEventListener("resize", handleResizeWindow);
    return () => {
      // unsubscribe "onComponentDestroy"
      window.removeEventListener("resize", handleResizeWindow);
    };
  }, []);

  const ref = useRef();
  useEffect(() => {
    const handler = (event) => {
      if (navbarOpen && ref.current && !ref.current.contains(event.target)) {
        setNavbarOpen(false);
      }
    };
    document.addEventListener("mousedown", handler);
    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", handler);
    };
  }, [navbarOpen]);

  const navbarLargest = () => {
    return (
      <div className="navbar-links-mobile" ref={ref}>
        <div className="navbar-products-tab" ref={ref}>
          <p className="navbar-products-title">Homa</p>
          <a className="navbar-products-button" href="about">
            <div className="navbar-icon-container">
            <img src={AboutUsIcon} alt="About Us Icon" />
            </div>{" "}
            About Us
          </a>
          <a className="navbar-products-button" href="solutions">
            <div className="navbar-icon-container">
            <img src={SolutionsIcon} alt="Solutions Icon" />
            </div>{" "}
            Solutions
          </a>
          <a className="navbar-products-button" href="../all-posts">
            <div className="navbar-icon-container">
            <img src={ArticlesIcon} alt="Articles Icon" />
            </div>{" "}
            Articles
          </a>
          <a className="navbar-products-button" href="experience-hub">
            <div className="navbar-icon-container">
            <img src={ArticlesIcon} alt="Experience Hub Icon" />
            </div>{" "}
            Experience Hub
          </a>
        </div>
        <div className="navbar-products-tab" ref={ref}>
          <p className="navbar-products-title">Products</p>
          <a className="navbar-products-button" href="../products-control">
            <div className="navbar-icon-container">
            <img src={SmartHomeIcon} alt="Smart Home Icon" />
            </div>{" "}
            Smart Home
          </a>
          <a className="navbar-products-button" href="../products-lighting">
            <div className="navbar-icon-container">
            <img src={HomaLightingIcon} alt="Homa Lighting Icon" />
            </div>{" "}
            Homa Lighting
          </a>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "12px",
            marginBottom: "12px",
          }}
        >
          <a
            className="navbar-button-border"
            style={{
              backgroundColor: "#161616",
              color: "white",
              width: "100%",
              marginLeft: "9px",
              marginRight: "9px",
            }}
            href="contact-us"
          >
            Contact Us
          </a>
        </div>
      </div>
    );
  };

  const navbarMedium = () => {
    return (
      <div className="navbar-links-mobile" ref={ref}>
        <div className="navbar-products-tab" ref={ref}>
          <p className="navbar-products-title">Homa</p>
          <a className="navbar-products-button" href="about">
            <div className="navbar-icon-container">
            <img src={AboutUsIcon} alt="About Us Icon" />
            </div>{" "}
            About Us
          </a>
          <a className="navbar-products-button" href="solutions">
            <div className="navbar-icon-container">
            <img src={SolutionsIcon} alt="Solutions Icon" />
            </div>{" "}
            Solutions
          </a>
          <a className="navbar-products-button" href="../all-posts">
            <div className="navbar-icon-container">
              <img src={ArticlesIcon} alt="Artciles Icon" />
            </div>{" "}
            Articles
          </a>
        </div>
        <div className="navbar-products-tab" ref={ref}>
          <p className="navbar-products-title">Products</p>
          <a className="navbar-products-button" href="../products-control">
            <div className="navbar-icon-container">
            <img src={SmartHomeIcon} alt="Smart Home Icon" />
            </div>{" "}
            Smart Home
          </a>
          <a className="navbar-products-button" href="../products-lighting">
            <div className="navbar-icon-container">
            <img src={HomaLightingIcon} alt="Homa Lighting Icon" />
            </div>{" "}
            Homa Lighting
          </a>
        </div>
      </div>
    );
  };

  const navbarSmallest = () => {
    return (
      <div className="navbar-links-mobile" ref={ref}>
        <div className="navbar-products-tab" ref={ref}>
          <p className="navbar-products-title">Products</p>
          <a className="navbar-products-button" href="../products-control">
            <div className="navbar-icon-container">
            <img src={SmartHomeIcon} alt="Smart Home Icon" />
            </div>{" "}
            Smart Home
          </a>
          <a className="navbar-products-button" href="../products-lighting">
            <div className="navbar-icon-container">
              <img src={HomaLightingIcon} alt="Homa Lighting Icon" />
            </div>{" "}
            Homa Lighting
          </a>
        </div>
      </div>
    );
  };

  return (
    // We'll add our JSX for the navbar here
    <div className="navbar-full-full">
      <div className="navbar-container">
        <div className="navbar-logo">
          <a href="/" className="link">
            <img src={Logo} className="logo-img" alt="Homa Logo" />
          </a>
        </div>
        {width > 999 ? (
          <div className="navbar-links-container">
            <a className="navbar-button-borderless" href="../about">
              About us
            </a>
            <a className="navbar-button-borderless" href="../experience-hub">
              Experience Homa
            </a>
            <a className="navbar-button-borderless" href="../solutions">
              Solutions
            </a>
            <button
              className="navbar-button-borderless navbar-button-products"
              onClick={() => setNavbarOpen((prev) => !prev)}
              style={
                {
                  /* make them centered */
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "3px",
                  padding: "0",

                }
              }
            >
              Products <FaAngleDown />
            </button>
            <a className="navbar-button-borderless" href="../all-posts">
              Articles
            </a>
            <a className="navbar-button-border" href="contact-us">
              Contact Us
            </a>
          </div>
        ) : (
          <div>
            {width > 700 ? (
              <div className="hamburger-icon-container">
                <a className="navbar-button-borderless" href="experience-hub">
                  Experience Homa
                </a>
                <a className="navbar-button-border" href="contact-us">
                  Contact Us
                </a>
                <button
                  className="navbar-hamburger-icon-button"
                  onClick={() => setNavbarOpen((prev) => !prev)}
                >
                  {navbarOpen ? (
                    <MdClose style={{ fontSize: "30px", color: "white" }} />
                  ) : (
                    <FiMenu
                      style={{
                        fontSize: "30px",
                        color: "white",
                      }}
                    />
                  )}
                </button>
              </div>
            ) : (
              <div className="hamburger-icon-container">
                <button
                  className="navbar-hamburger-icon-button"
                  onClick={() => setNavbarOpen((prev) => !prev)}
                >
                  {navbarOpen ? (
                    <MdClose style={{ fontSize: "30px", color: "white" }} />
                  ) : (
                    <FiMenu
                      style={{
                        fontSize: "30px",
                        color: "white",
                      }}
                    />
                  )}
                </button>
              </div>
            )}
          </div>
        )}
      </div>
      {navbarOpen ? (
        <div>
          {width > 999 ? navbarSmallest() : width > 700 ? navbarMedium() : navbarLargest()}
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default Navbar;
