import React from 'react';
import Error404Img from '../imagesNew/Error404Img.png';
import '../css/ErrorPage.css';


function ErrorPage() {
    return (
      <div className="error-container">
          <img src={Error404Img} alt="Error 404" />
          <h1 className="error-title-text">ERROR 404</h1>
          <p className="error-body-text">Looks like we are disconnected, give us a while to get everything back up and running. Meanwhile, do check out our Instagram for latest news and updates!</p>
      </div>
    );
  }
  
  export default ErrorPage;