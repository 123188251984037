import React from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

import '../css/ReviewCarousel.css'; // Ensure this CSS file exists and is imported

const reviews = [
  { id: 1, text: '"Security was my top concern when I was looking at building a smart home. The smart locks, cameras, and alarm system provided a sense of safety and peace of mind."', name: "Anna L.", shortDescription: "admin" },
  { id: 2, text: '"I\'m truly amazed by the seamless integration of smart devices in my home. From lighting to security, everything is super convenient and my family really loves the lighting effects."', name: "John M.", shortDescription: "admin" },
  { id: 3, text: '"As a busy professional, having a smart home has been a game-changer. I can control my lights and security system remotely, making my life much more efficient."', name: "Sarah K.", shortDescription: "admin" },
  { id: 4, text: '"Our kids enjoy voice-controlled lighting, and we feel more secure with the integrated camera system. Our homes feel much more modern and high-tech now!"', name: "David and Lisa R.", shortDescription: "admin" },
  { id: 5, text: '"Homa helped me transform my living room into a smart entertainment hub. My whole family enjoys the smart lighting system and automated scenes. Movie nights have never been this fun!"', name: "Carlos P.", shortDescription: "admin" },
  { id: 6, text: '"I love the ability to adjust colour temperatures and brightness levels, it brings a touch of elegance to my home, and I really enjoy the different ambience the lighting can portray!"', name: "Grace W.", shortDescription: "admin" },
  { id: 7, text: '"I like how I am able to see the lighting effects through rendering videos and simulations first before deciding on what lights to get from Homa. This additional service they provide really makes a huge difference!"', name: "Brian M.", shortDescription: "admin" },
  { id: 8, text: '"Got to talk to the team at Homa at their showroom and they showed me an extensive range of products, from lighting to hubs and sensors. Pleasant experience customising my smart home!"', name: "Sophie L.", shortDescription: "admin" },
];

const ReviewCarousel = () => {
  const [width, setWidth] = React.useState(window.innerWidth);
  const [currentIndex, setCurrentIndex] = React.useState(2);

  React.useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }
  );

  return (
    <div className="review-container">
      <p className="review-top-text">From the people who choose to live smart.</p>
      <div className="review-carousel">
      <Carousel
        infiniteLoop={false}
        centerMode={true}
        centerSlidePercentage={width > 1000 ? 20 : width > 700 ? 30 : 60}
        showIndicators={false}
        showThumbs={false}
        showStatus={false}
        showArrows={false}
        selectedItem={currentIndex}        
      >
                {reviews.map((review) => (
          <div key={review.id} className="review-item">
            <p className='review-text'>{review.text}</p>
            <p className='review-name'>{review.name}</p>
          </div>
        ))}
            </Carousel>
    </div>
    <div className='review-carousel-arrows'>
      <div className='review-carousel-arrow-left'
      onClick={() => {
        if (currentIndex > 0) {
          setCurrentIndex(currentIndex - 1);
        } else {
          setCurrentIndex(reviews.length - 1);
        }
      }
    }
      >{"<"}</div>
      <div className='review-carousel-arrow-right'
      onClick={() => {
        if (currentIndex < reviews.length - 1) {
          setCurrentIndex(currentIndex + 1);
        } else {
          setCurrentIndex(0);
        }
      }
    }
      >{">"}</div>
    </div>
    </div>
    
  );
};

export default ReviewCarousel;
