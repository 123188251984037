import React, { useEffect, useState } from "react";
import { useTransition, animated } from "react-spring";
import GoogleCalendarButton from "../components/GoogleCalendarButton";

import "../css/ExperienceHub.css";

import Dining from "../imagesNew/ExperienceHub/Dining.png";
import Gathering from "../imagesNew/ExperienceHub/Gathering.png";
import Movie from "../imagesNew/ExperienceHub/Movie.png";

function ExperienceHub() {
  const [currentImage, setCurrentImage] = useState(0);
  const [visibleCalendar, setVisibleCalendar] = useState(false);

  React.useEffect(() => {
    setVisibleCalendar(visibleCalendar);
  }, [visibleCalendar]);


  function preloadImages(images) {
    for (let i = 0; i < images.length; i++) {
      new Image().src = images[i];
    }
  }

  const images = [
    Movie,
    Gathering,
    Dining,
  ]

  const transitions = useTransition(currentImage, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 1 },
    config: { duration: 1000 },
  });

  useEffect(() => {
    preloadImages(images);
  }, []);

  return (
    <div className="ExperienceHub">
      <div className="experiencehub_hero_img_div">
        <div className="experiencehub_hero_img_stretched_container">
        {transitions((style, i) => (
          <animated.img
            key={i}
            style={{ ...style, position: "absolute",
          }}
            src={images[i]}
            alt={`Living Room View ${i + 1}`}
            className="experiencehub_hero_img_stretched"
          />
        ))}
        <div className="experiencehub_hero_button">
            <button
              className={
                currentImage === 0
                  ? "experiencehub_button_active"
                  : "experiencehub_button_unactive"
              }
              onClick={() => setCurrentImage(0)}
            >
              Immerse
            </button>
            <button
              className={
                "experiencehub_hero_button_left " +
                (currentImage === 1
                  ? "experiencehub_button_active"
                  : "experiencehub_button_unactive")
              }
              onClick={() => setCurrentImage(1)}
            >
              Lounge
            </button>
            <button
              className={
                "experiencehub_hero_button_left " +
                (currentImage === 2
                  ? "experiencehub_button_active"
                  : "experiencehub_button_unactive")
              }
              onClick={() => setCurrentImage(2)}
            >
              Dining
            </button>
        </div>
        </div>
          
      </div>
      <div className="experiencehub_body_text_div">
        <p className="experiencehub_body_title">Let’s make your Home, Smart.</p>
        <div className="experiencehub_body_texts">
          <p className="experiencehub_body_text">
            Experience the future of smart living at our showroom!
          </p>

          <p className="experiencehub_body_text">
            See how smart home automation and scene automation can make your
            life easier, more convenient, and more energy-efficient. Our team of
            experts will be on hand to answer your questions and help you create
            the perfect smart home system for your needs.
          </p>
        </div>

        {!visibleCalendar && (
        <div className="experiencehub_body_text_button">
          <a>
            <button onClick={() =>
              setVisibleCalendar(true)
            }>Experience Homa &nbsp; → </button>
          </a>
        </div>
        )}
          {visibleCalendar && (
                    <div style={{
                      marginTop: "-20px",
                    }}>
            <GoogleCalendarButton />
            </div>
          )}

      </div>
    </div>
  );
}

export default ExperienceHub;
