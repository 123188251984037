import React, { useEffect, useRef } from "react";

const GoogleCalendarButton = () => {
  const calendarButtonRef = useRef(null);
  const width = window.innerWidth;
  const marginTop = width > 700 ? "0px" : "32px";
  const marginSides = width > 1000 ? "11%" : width > 700 ? "6%" : "7.5%";


  useEffect(() => {
    const head = document.querySelector("head");
    const script = document.createElement("script");
    script.setAttribute("src", "https://assets.calendly.com/assets/external/widget.js");
    script.setAttribute("async", true);
    head.appendChild(script);
    const style = document.createElement("style");
    style.innerHTML = `
      .calendly-inline-widget {
        margin-top: ${marginTop};
        height: 1010px;
        min-width: 320px;
        margin-left: ${marginSides};
        margin-right: ${marginSides};
      }
    `;
    head.appendChild(style);
    return () => {
      head.removeChild(script);
      head.removeChild(style);
    };
  }
  , [marginTop]);

  return (
    <div ref={calendarButtonRef} className="calendly-inline-widget" data-url="https://calendly.com/hello-b24/homa"></div>
  );
}

export default GoogleCalendarButton;