import React from "react";
import ImageTransition from "../components/ImageTransition";
import ReactCompareImage from "react-compare-image";
import CTAImage from "../components/CTAImage";

import ExperienceVideo from "../imagesNew/Home/Homa Experience Video.mp4";

import LivetheDreamHomeControl from "../imagesNew/Home/Home Control - Desktop Section.png";
import LivetheDreamSmartLighting from "../imagesNew/Home/Homa Lighting - Desktop Section.png";

import Icon1 from "../imagesNew/Home/HomeKit Icon.png";
import Icon2 from "../imagesNew/Home/free-google-home-2981837-2476489.png";
import Icon3 from "../imagesNew/Home/Amazon Alexa.png";
import Icon4 from "../imagesNew/Home/Samsung Smart Things.png";

import SliderBefore from "../imagesNew/Home/Before.png";
import SliderAfter from "../imagesNew/Home/After.png";

import PhoneImage from "../imagesNew/Home/Phone_Rings.png";

import { useState, useEffect } from "react";

import "../css/Home.css";
import ReviewsCarousel from "../components/ReviewsCarousel";

// i want to have a image where when you scroll it will transition to a different image

function Home() {
  const [scrollPosition, setScrollPosition] = useState(0);
  const [finishedScrolling, setFinishedScrolling] = useState(false);

  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  /* when setFinishedScrolling == true, make the user jump back to the top of the page */
  useEffect(() => {
    if (!finishedScrolling) {
      if (scrollPosition / window.document.documentElement.clientHeight > 1.5) {
        setFinishedScrolling(true);
        window.scrollTo(88, 0);
      }
    }
  }, [scrollPosition]);

  return (
    <div className="HomePage">
      <ImageTransition />
      <div
        style={{
          visibility: finishedScrolling ? "visible" : "hidden",
        }}
      >
        <div style={
          {
            boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.25)",
          position:"relative",
          }
        }>

        
        <div className="home-page-experience-section">
          <div className="home-page-text-section">
            <p className="home-page-text-title padding-remover">Experience the Dream</p>
            <p className="home-page-text-body padding-remover">
            Step into our immersive showroom and witness smart homes come alive. Lights dance, speakers sing, and every space hums with intelligence. 
            </p>
            <div className="contactus_visit_us_button padding-remover">
              <a href="experience-hub">
                <button>Experience Homa &nbsp; → </button>
              </a>
            </div>
          </div>
          <div className="home-page-experience-video-container">
            <video
              className="home-page-experience-video"
              autoPlay
              loop
              muted
              playsinline
              src={ExperienceVideo}
            ></video>
            </div>
        </div>
        </div>
        <div style={{
          backgroundColor: "#F2F2F2",
        }} >
          <div className="home-page-dream-section">
        <div className="home-page-text-section">
          <p className="home-page-text-title padding-remover">Feel the Dream</p>
          <p className="home-page-text-body padding-remover">
            Dream It. See It. Own It: Share your vision, watch us transform your
            blueprint into a stunning 3D haven. See sleek smart tech woven into
            your life, pixel by pixel. Your dream home, rendered real.
          </p>
          <div className="contactus_visit_us_button padding-remover">
            <a href="solutions">
              <button>See our Solutions &nbsp; → </button>
            </a>
          </div>
          <div className="home-page-slider-images">
          <div className="home-page-slider-image">
          <ReactCompareImage
            leftImage={SliderBefore}
            rightImage={SliderAfter}
          />
          </div>

        </div>
        </div>
        </div>
        </div>
        <div 
        style={{
          paddingBottom: "54px",
          paddingTop: "54px",
          boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.25)",
          position:"relative",
        }}>
        <div className="home-page-live-the-dream-section">
          <p className="home-page-live-the-dream-section-title">
            Live the Dream
          </p>
          <p className="home-page-live-the-dream-section-body">
            We have the products that give you full control of nearly everything
            at home - no matter where you may be.
          </p>
          <div className="home-page-live-the-dream-section-images">
            <div className="home-page-live-the-dream-section-images-img-container" onClick={() => window.location.href = "../products-control"} style={{cursor: "pointer"}}>
              <img
                className="home-page-live-the-dream-section-images-img"
                src={LivetheDreamHomeControl}
                alt="Image1"
              />
              <p className="home-page-live-the-dream-section-images-img-text">
                Home Control
              </p>
            </div>
            <div className="home-page-live-the-dream-section-images-img-container" onClick={() => window.location.href = "../products-lighting"} style={{cursor: "pointer"}}>
              <img
                className="home-page-live-the-dream-section-images-img"
                src={LivetheDreamSmartLighting}
                alt="Image1"
              />
              <p className="home-page-live-the-dream-section-images-img-text">
              Homa Lighting
              </p>
            </div>
          </div>
          <p className="home-page-live-the-dream-section-title bottom">Ready to make your dream real? 
            <br />
            Let's get started.</p>
          <div className="cta-section-button">
            <a href="contact-us">
              <button>Get started today &nbsp; → </button>
            </a>
          </div>
        </div>
        </div>

        <div style={{
          backgroundColor: "#F2F2F2",
        }} >
        <div className="home-page-phone-section">
          <div className="home-page-phone-section-without-phone">
            <div className="home-page-phone-section-icons">
              <img
                className="home-page-phone-section-icons-icon"
                src={Icon1}
                alt="Image1"
              />
              <img
                className="home-page-phone-section-icons-icon"
                src={Icon2}
                alt="Image1"
              />
              <img
                className="home-page-phone-section-icons-icon"
                src={Icon3}
                alt="Image1"
              />
              <img
                className="home-page-phone-section-icons-icon"
                src={Icon4}
                alt="Image1"
              />
            </div>

            <p className="home-page-phone-section-title">
              Power of your home, in your fingertips
            </p>
            <p className="home-page-phone-section-body">
              Home Control, Security & Convenience. All within your grasp.
            </p>
            <button className="home-page-phone-section-read-more" onClick={() => window.location.href = "experience-hub"}>
              Read More
            </button>
          </div>
          <div className="home-page-phone-section-img-container">
            <img
              className="home-page-phone-section-img"
              src={PhoneImage}
              alt="Image1"
            />
          </div>
        </div>
        <div className="home-page-max-width-sections">
        <ReviewsCarousel />
        </div>

        <div className="home-page-max-width-sections">
        <CTAImage />
        </div>
        
      </div>
      </div>
    </div>
  );
}

export default Home;
