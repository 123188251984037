import React from "react";
import { FaFacebookF, FaInstagram, FaYoutube } from "react-icons/fa";
import "../css/Footer.css"; // Make sure to create this CSS file
import Logo from "../imagesNew/Homa Header Logo-01.png";
import TermsOfUse from "../downloadfiles/Homa Hub Terms of Use.pdf";
import PrivacyPolicy from "../downloadfiles/Homa Hub Privacy Policy.pdf";

const Footer = () => {
  const [width, setWidth] = React.useState(window.innerWidth);
  const [showProduct, setShowProduct] = React.useState(false);
  React.useEffect(() => {
    const handleResizeWindow = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResizeWindow);
    return () => {
      window.removeEventListener("resize", handleResizeWindow);
    };
  }, []);

  return (
    <div className="footer-container">
      {width < 700 ? (
        <footer className="site-footer-container">
          <div className="footer-biggest-group">
            <div className="footer-logo-container">
              <img src={Logo} alt="Logo" className="footer-logo-img" />
              <p className="footer-logo-text">Living Reimagined.</p>

              <div className="footer-address-text">
                <p className="footer-address-title-text">Address</p>
                <p
                  className="footer-address-body-text"
                  onClick={() =>
                    window.open("https://maps.app.goo.gl/gbdBCYRgxDSpdG7CA")
                  }
                >
                  101 Geylang Lor 23
                </p>
                <p
                  className="footer-address-body-text"
                  onClick={() =>
                    window.open("https://maps.app.goo.gl/gbdBCYRgxDSpdG7CA")
                  }
                >
                  Prosper House
                </p>
                <p
                  className="footer-address-body-text"
                  onClick={() =>
                    window.open("https://maps.app.goo.gl/gbdBCYRgxDSpdG7CA")
                  }
                >
                  #01-02 Singapore 388399
                </p>
              </div>
            </div>
            <div className="footer-links-container">
              <a href="/about">About Us</a>
              <a href="/experiencehub">Experience Homa</a>
              <a href="/solutions">Solutions</a>
              <div>
              <p
                className="footer-product"
                onClick={() => setShowProduct(!showProduct)}
              >
                Products {">"}
              </p>
              {showProduct ? (
                <div className="footer-product-mini-text-container">
                  <a className="footer-product-mini-text" href="../products-control">
                  Home Control
                  </a>
                  <a className="footer-product-mini-text" href="../products-lighting">
                  Homa Lighting
                  </a>
                </div>
              ) : (
                ""
              )}
              </div>

              <a href="/all-posts">Articles</a>
              <a href="/contact-us">Contact Us</a>
            </div>
          </div>

          <div className="social-media-footer">
            <div className="icons-and-links">
              <div className="social-icons">
                <a
                  href="https://www.instagram.com/homahubsg/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaInstagram
                    style={{
                      fontSize: "20px",
                      color: "white",
                    }}
                  />
                </a>
                <a
                  href="https://www.facebook.com/profile.php?id=61554961765127"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaFacebookF
                    style={{
                      fontSize: "20px",
                      color: "white",
                    }}
                  />
                </a>
                <a
                  href="https://www.youtube.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaYoutube
                    style={{
                      fontSize: "20px",
                      color: "white",
                    }}
                  />
                </a>
              </div>
              <div className="legal-links">
                <a onClick={
                  /* download a file */
                  () => {
                    window.open(TermsOfUse);
                  }
                  
                }>Terms of Use</a> |{" "}
                <a onClick={
                  /* download a file */
                  () => {
                    window.open(PrivacyPolicy);
                  }
                }
                >Privacy</a>
              </div>
            </div>
            <hr />
            <p className="copyright-text">
              Copyright C 2023 Homa Hub. All Rights Reserved.
            </p>
          </div>
        </footer>
      ) : (
        <footer className="site-footer-container">
          <div className="footer-biggest-group">
            <div className="footer-logo-container">
              <img src={Logo} alt="Logo" className="footer-logo-img" />
              <p className="footer-logo-text">Living Reimagined.</p>
            </div>
            <div className="footer-links-container-and-address">
              <div className="footer-links-container">
                <a href="/about">About Us</a>
                <a href="/experiencehub">Experience Homa</a>
                <a href="/solutions">Solutions</a>
                <p
                  className="footer-product"
                  onClick={() => setShowProduct(!showProduct)}
                >
                  Products {">"}
                </p>
                {showProduct ? (
                <div className="footer-product-mini-text-container">
                  <a className="footer-product-mini-text" href="../products-control">
                  Home Control
                  </a>
                  <a className="footer-product-mini-text" href="../products-lighting">
                  Homa Lighting
                  </a>
                </div>
              ) : (
                ""
              )}
                <a href="/all-posts">Articles</a>
                <a href="/contact-us">Contact Us</a>
              </div>
              <div className="footer-address-text">
                <p className="footer-address-title-text">Address</p>
                <p
                  className="footer-address-body-text"
                  onClick={() =>
                    window.open("https://maps.app.goo.gl/gbdBCYRgxDSpdG7CA")
                  }
                >
                  101 Geylang Lor 23
                </p>
                <p
                  className="footer-address-body-text"
                  onClick={() =>
                    window.open("https://maps.app.goo.gl/gbdBCYRgxDSpdG7CA")
                  }
                >
                  Prosper House
                </p>
                <p
                  className="footer-address-body-text"
                  onClick={() =>
                    window.open("https://maps.app.goo.gl/gbdBCYRgxDSpdG7CA")
                  }
                >
                  #01-02 Singapore 388399
                </p>
              </div>
            </div>
          </div>

          <div className="social-media-footer">
            <div className="icons-and-links">
              <div className="social-icons">
                <a
                  href="https://www.instagram.com/homahubsg/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaInstagram
                    style={{
                      fontSize: "28px",
                      color: "white",
                    }}
                  />
                </a>
                <a
                  href="https://www.facebook.com/profile.php?id=61554961765127"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaFacebookF
                    style={{
                      fontSize: "28px",
                      color: "white",
                    }}
                  />
                </a>
                <a
                  href="https://www.youtube.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaYoutube
                    style={{
                      fontSize: "28px",
                      color: "white",
                    }}
                  />
                </a>
              </div>
              <div className="legal-links">
                <a href="/terms-of-use">Terms of Use</a> |{" "}
                <a href="/privacy">Privacy</a>
              </div>
            </div>
            <hr />
            <p className="copyright-text">
              Copyright © 2024 Homa Hub. All Rights Reserved.
            </p>
          </div>
        </footer>
      )}
    </div>
  );
};

export default Footer;
