import React, { useEffect } from "react";
import { useState } from "react";
import { initializeApp } from "firebase/app";
import { getFirestore, collection, getDocs } from "firebase/firestore/lite";
import "../css/AllPostsPage.css";
import ReactLoading from "react-loading";

const firebaseConfig = {
  apiKey: "AIzaSyDqEyNt5rs8cke7JUpdZ1Vvept205vWCPc",
  authDomain: "smart-home-homa.firebaseapp.com",
  projectId: "smart-home-homa",
  storageBucket: "smart-home-homa.appspot.com",
  messagingSenderId: "474534868889",
  appId: "1:474534868889:web:3158e22d3d37221fa9c508",
  measurementId: "G-PR6SRFY2W5",
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

// Get a list of cities from your database
async function getArticles(db) {
  const ArticlesCol = collection(db, "articles");
  const ArticlesSnapshot = await getDocs(ArticlesCol);
  const ArticlesList = ArticlesSnapshot.docs.map((doc) => doc.data());
  return ArticlesList;
}

async function getBlogs(db) {
  const BlogsCol = collection(db, "blogs");
  const BlogsSnapshot = await getDocs(BlogsCol);
  const BlogsList = BlogsSnapshot.docs.map((doc) => doc.data());
  return BlogsList;
}

const AllPostsPage = () => {
  const [currentArticleType, setCurrentArticleType] = useState(1);
  const [ArticlesList, setArticlesList] = React.useState([]);
  const [BlogsList, setBlogsList] = React.useState([]);
  const [PostsList, setPostsList] = React.useState([]);
  const [width, setWidth] = React.useState(window.innerWidth);
  const [page, setPage] = React.useState(0);
  const [postsPerPage, setPostsPerPage] = React.useState(2);

  React.useEffect(() => {
    getArticles(db).then((newsList) => {
      newsList.forEach((article) => {
        article.date = article.date.toDate().toLocaleDateString("en-US");
      });
      setArticlesList(newsList);
      setPostsList(newsList);
    });
  }, []);

  React.useEffect(() => {
    getBlogs(db).then((newsList) => {
      newsList.forEach((article) => {
        article.date = article.date.toDate().toLocaleDateString("en-US");
      });
      setBlogsList(newsList);
    });
  }, []);

  /* preload images */
  ArticlesList.forEach((post) => {
    new Image().src = post.image;
  });

  BlogsList.forEach((post) => {
    new Image().src = post.image;
  });

  React.useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (currentArticleType === 0) {
      setPostsList(BlogsList);
      setPage(0);
    } else {
      setPostsList(ArticlesList);
      setPage(0);
    }
  }, [currentArticleType]);

  /* when setPage is called, scroll to top of page */
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [page]);

  React.useEffect(() => {
    if (width < 1200) {
      setPostsPerPage(3);
    } else {
      setPostsPerPage(9);
    }
  }, [width]);

  return (
    <div className="all-posts-page-entire">
      <p className="all-posts-page-entire-top-text">Articles</p>
      <p className="all-posts-page-entire-title">Latest Guides & Blog</p>
      <p className="all-posts-page-entire-body-text">
        Provide insights into the world of Smart Home Automation
      </p>
      <div className="all-posts-page-entire-blog-article-buttons">
        <button
          className={
            currentArticleType === 1
              ? "post_button_active"
              : "post_button_unactive"
          }
          onClick={() => setCurrentArticleType(1)}
        >
          Guides
        </button>
        <button
          className={
            currentArticleType === 0
              ? "post_button_active"
              : "post_button_unactive"
          }
          onClick={() => setCurrentArticleType(0)}
        >
          Blogs
        </button>
      </div>

      <div className="all-posts-loaded-container">
        {PostsList.length <= 0 && (
          <div className="all-posts-loading">
            <ReactLoading type={"spin"} height={300} width={300} />
          </div>
        )}
        {PostsList.slice(page * postsPerPage, postsPerPage * (page + 1)).map(
          (post, index) => (
            <div key={index} className="all-posts-blog-post">
              <button
                className="all-posts-blog-post-button"
                onClick={() => {
                  // Navigate to the post's page
                  window.location.href = "all-posts/" + post.title;
                }}
              >
                <img
                  src={post.image}
                  alt={post.title}
                  className="all-posts-blog-posts-image"
                />
                <p className="all-posts-blog-posts-title">{post.title}</p>
                <p className="all-posts-blog-posts-shorttext">
                  {/* first 7 words */}
                  {post.text.split(" ").slice(0, 7).join(" ")} ...
                </p>
                <button
                  className="all-posts-blog-posts-readmore"
                  onClick={() => {
                    // Navigate to the post's page
                    window.location.href = "all-posts/" + post.title;
                  }}
                >
                  READ MORE
                </button>
              </button>
            </div>
          )
        )}
      </div>
      {page === 0 && ArticlesList !== 0 && (
        <div className="all-products-page-change-button-row">
          <button
          className="all-posts-button-number active"
          onClick={() => setPage(page)}
        >
          {page + 1}
        </button>
        {PostsList.length > (postsPerPage * (page + 1)) && (
        <button
                className={"all-posts-button-number" + (page * postsPerPage + postsPerPage < PostsList.length ? "" : " disabled")}
                onClick={() => page * postsPerPage + postsPerPage < PostsList.length ? setPage(page + 1) : setPage(page)}
        >
          {page + 2}
        </button>
        )}
        {PostsList.length > (postsPerPage * (page + 2)) && (
        <button
        className={"all-posts-button-number" + (page * postsPerPage + (postsPerPage * 2) < PostsList.length ? "" : " disabled")}
        onClick={() => page * postsPerPage + (postsPerPage * 2) < PostsList.length ? setPage(page + 2) : setPage(page)}
        >
          {page + 3}
        </button>
        )}
        {PostsList.length > (postsPerPage * (page + 1)) && (
        <button
                    className={"all-products-button-change" + (page * postsPerPage + postsPerPage < PostsList.length ? "" : " disabled")}
                    onClick={() => page * postsPerPage + postsPerPage < PostsList.length ? setPage(page + 1) : setPage(page)}
          
        >
          Next
        </button>
        )}
        </div>
      )}
      {page !== 0 && ArticlesList !== 0 && (
        <div className="all-products-page-change-button-row">
          <button
          className="all-products-button-change"
          onClick={() => setPage(page-1)}
        >
          Previous
          </button>
          <button
          className="all-posts-button-number"
          onClick={() => setPage(page-1)}
        >
          {page}
        </button>
        <button
          className="all-posts-button-number active"
          onClick={() => setPage(page)}
        >
          {page + 1}
        </button>
        {PostsList.length > (postsPerPage * (page + 2)) && (
        <button
          className={"all-posts-button-number" + (page * postsPerPage + postsPerPage < PostsList.length ? "" : " disabled")}
          onClick={() => page * postsPerPage + postsPerPage < PostsList.length ? setPage(page + 1) : setPage(page)}
        >
          {page + 2}
        </button>
        )}
        {PostsList.length > (postsPerPage * (page + 2)) && (
        <button
          className={"all-products-button-change" + (page * postsPerPage + postsPerPage < PostsList.length ? "" : " disabled")}
          onClick={() => page * postsPerPage + postsPerPage < PostsList.length ? setPage(page + 1) : setPage(page)}
        >
          Next
        </button>
        )}
        </div>
      )}
    </div>
  );
};

export default AllPostsPage;

/*<button
          className="all-posts-blog-posts-see-more"
          onClick={() => {
            if (width < 700) {
              increasePostsPerPage();
            } else {
              handlePageChange();
            }
          }}
        >
          View More
        </button> */
