import React from "react";
import NewsPage from "./NewsPage";
import { initializeApp } from "firebase/app";
import { getFirestore, collection, getDocs } from "firebase/firestore/lite";
import ReactLoading from "react-loading";

const firebaseConfig = {
  apiKey: "AIzaSyDqEyNt5rs8cke7JUpdZ1Vvept205vWCPc",
  authDomain: "smart-home-homa.firebaseapp.com",
  projectId: "smart-home-homa",
  storageBucket: "smart-home-homa.appspot.com",
  messagingSenderId: "474534868889",
  appId: "1:474534868889:web:3158e22d3d37221fa9c508",
  measurementId: "G-PR6SRFY2W5",
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

async function getArticles(db) {
  const ArticlesCol = collection(db, "articles");
  const ArticlesSnapshot = await getDocs(ArticlesCol);
  const ArticlesList = ArticlesSnapshot.docs.map((doc) => doc.data());
  return ArticlesList;
}

async function getBlogs(db, slug) {
  const BlogsCol = collection(db, "blogs");
  const BlogsSnapshot = await getDocs(BlogsCol);
  const BlogsList = BlogsSnapshot.docs.map((doc) => doc.data());
  return BlogsList;
}

const PostPage = () => {
  let slug = window.location.href;
  slug = slug.split("/").pop();
  slug = slug.replace(/%20/g, " ");

  const [post, setPost] = React.useState(null);
  const [ArticlesList, setArticlesList] = React.useState([]);
  const [BlogsList, setBlogsList] = React.useState([]);

  React.useEffect(() => {
    getArticles(db).then((newsList) => {
      newsList.forEach((article) => {
        article.date = article.date.toDate().toLocaleDateString("en-US");
      });
      setArticlesList(newsList);
    });

    getBlogs(db).then((blogsList) => {
      blogsList.forEach((blog) => {
        blog.date = blog.date.toDate().toLocaleDateString("en-US");
      });
      setBlogsList(blogsList);
    });
  }, []);

  React.useEffect(() => {
    ArticlesList.forEach((post) => {
      if (post.title === slug) {
        setPost(post);
      }
    });
    BlogsList.forEach((post) => {
      if (post.title === slug) {
        setPost(post);
      }
    });
  }, [ArticlesList, BlogsList, slug]);

  let dateTiest = new Date(post?.date);
  dateTiest = dateTiest.toLocaleDateString("en-US", {
    year: "numeric",
    month: "short",
    day: "numeric",
  });
  dateTiest = dateTiest.replace(/,/g, "");
  dateTiest = dateTiest.split(" ");
  dateTiest = dateTiest[1] + " " + dateTiest[0] + " " + dateTiest[2];

  if (!post) {
    return (
      <div
        className="loading"
        style={{
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <ReactLoading type="spin" height={200} width={200} />
      </div>
    );
  }
  return (
    <NewsPage
      title={post?.title}
      text={post?.text}
      date={dateTiest}
      author={post?.author}
      image={post?.image}
      headimage={post?.headimage}
    />
  );
};

export default PostPage;
