import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { initializeApp } from "firebase/app";
import { getFirestore, collection, getDocs } from "firebase/firestore/lite";
import "../css/AllProductsPage.css";
import ReactLoading from "react-loading";

import heroImage from "../imagesNew/ProductsPage/experienceHubBuisinessReception.png";
import HomaLighting from "../imagesNew/Home/Homa Lighting - Desktop Section.png";
import CTAImage from "../components/CTAImage";

import { FaAngleLeft, FaAngleRight } from "react-icons/fa";


const firebaseConfig = {
  apiKey: "AIzaSyDqEyNt5rs8cke7JUpdZ1Vvept205vWCPc",
  authDomain: "smart-home-homa.firebaseapp.com",
  projectId: "smart-home-homa",
  storageBucket: "smart-home-homa.appspot.com",
  messagingSenderId: "474534868889",
  appId: "1:474534868889:web:3158e22d3d37221fa9c508",
  measurementId: "G-PR6SRFY2W5",
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

// Get a list of cities from your database
async function getProducts(db) {
  const ProductsCol = collection(db, "products");
  const ProductsSnapshot = await getDocs(ProductsCol);
  const ProductsList = ProductsSnapshot.docs.map((doc) => doc.data());
  return ProductsList;
}

const AllProductsPage = () => {
  const [currentProductType, setCurrentProductType] = useState(null);
  const [ProductsList, setProductsList] = useState([]);
  const [TotalProductList, setTotalProductList] = React.useState([]);
  const [width, setWidth] = React.useState(window.innerWidth);
  const [page, setPage] = React.useState(0);
  const [productsPerPage, setProductsPerPage] = React.useState(3);
  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      /* go to section HomeControlSection no matter what */
      const element = document.getElementById("HomeControlSection");
      if (element) {
        element.scrollIntoView();
      }
    }
  }, [location]);

  const productTypes = [
    "Hubs",
    "Switches",
    "Sensors",
    "Door Locks",
    "Curtains",
    "Cameras",
    "Lighting",
  ];

  const productTypesMini = [
    "Hubs",
    "Switches",
    "Sensors",
    "Door",
    "Curtains",
    "Cameras",
    "Lighting",
  ];

  React.useEffect(() => {
    getProducts(db).then((productList) => {
      setTotalProductList(productList);
      if (location.hash) {
        setCurrentProductType(
          productTypes.indexOf(location.hash.replace("#", ""))
        );
      } else {
        setCurrentProductType(0);
      }
    });
  }, []);

  /* preload images */
  ProductsList.forEach((product) => {
    new Image().src = product.images;
  });

  React.useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    setProductsList(
      TotalProductList.filter(
        (product) => product.type === productTypes[currentProductType]
      )
    );
  }, [currentProductType]);

  function handleProductTypeChange(type) {
    setPage(0);
    setCurrentProductType(type);
  }

  React.useEffect(() => {
    if (width > 1000) {
      setProductsPerPage(9);
    } else if (width > 700) {
      setProductsPerPage(6);
    } else {
      setProductsPerPage(3);
    }
  }, [width]);

  const counter = productsPerPage * (page + 1) - ProductsList.length;

  return (
    <div>
      <div className="all-products_hero_img_stretched_container">
        <img
          style={{ position: "absolute" }}
          src={heroImage}
          alt={`Test`}
          className="all-products_hero_img_stretched"
        />
      </div>

      <div className="all-products-page-entire" id="HomeControlSection">
        <p className="all-products-page-entire-top-text">HOMA</p>
        <p className="all-products-page-entire-title">Smart Home Products</p>
        <p className="all-products-page-entire-body-text">
          Browse the Homa range of smart home products. These are the heart of
          your smart home, through which you can connect to all your smart
          devices.
        </p>
        {width < 700 ? (
          <div className="all-products-button-row-mini">
              <FaAngleLeft
              className="product_type_button_arrow_mini"
              onClick={() => handleProductTypeChange(currentProductType === 0 ? 6 : currentProductType-1)}
             />
            {currentProductType === 0 ? (
              <button className="product_type_button_mini_unactive"
              onClick={() => handleProductTypeChange(6)}
            >
              {productTypesMini[6]}
            </button>

            ) : (            <button
              className="product_type_button_mini_unactive"
              onClick={() => handleProductTypeChange(currentProductType-1)}
            >
              {productTypesMini[currentProductType-1]}
            </button>
              
            )}

              <button
                className="product_type_button_larger_active"
                onClick={() => handleProductTypeChange(currentProductType)}
              >
                {productTypes[currentProductType]}
              </button>
              {currentProductType === 6 ? (
                <button className="product_type_button_mini_unactive"
                onClick={() => handleProductTypeChange(0)}
              >
                {productTypesMini[0]}
              </button>

            ) : (
              <button
              className="product_type_button_mini_unactive"
              onClick={() => handleProductTypeChange(currentProductType+1)}
            >
              {productTypesMini[currentProductType+1]}
            </button>
              
            )}
              <FaAngleRight
              className="product_type_button_arrow_mini"
              onClick={() => handleProductTypeChange(currentProductType === 6 ? 0 : currentProductType+1)}
             />
          </div>
        ) : (
          <div className="all-products-button-row">
            {productTypes.map((productType, index) => (
              <button
                className={
                  currentProductType === index
                    ? "product_type_button_active"
                    : "product_type_button_unactive"
                }
                onClick={() => handleProductTypeChange(index)}
              >
                {productType}
              </button>
            ))}
          </div>
        )}
        <div className="all-products-loaded-container">
          {ProductsList.length <= 0 && (
            <div className="all-products-loading">
              <ReactLoading type={"spin"} height={300} width={300} />
            </div>
          )}
          {ProductsList.slice(
            page * productsPerPage,
            productsPerPage * (page + 1)
          ).map((product, index) => (
            <div key={index} className="all-products-product">
              <button
                className="all-products-products-container-button"
                onClick={() => {
                  window.location.href = "../products-control/" + product.type + "%20" + product.name.split("/").join("");
                }}
              >
                <img
                  src={product.images}
                  alt={product.name}
                  className="all-products-product-image"
                />
                <p className="all-products-product-title">{product.name}</p>
                <button
                  className="all-products-product-learnmore-button"
                  onClick={() => {
                    window.location.href = "../products-control/" + product.name;
                  }}
                >
                  Learn More
                </button>
              </button>
            </div>
          ))}
          {counter == 4 && (
            <div
              className="all-products-product"
              style={{ visibility: "hidden" }}
            >
              <button className="all-products-products-container-button"></button>
            </div>
          )}
          {counter == 1 && (
            <div
              className="all-products-product"
              style={{ visibility: "hidden" }}
            >
              <button className="all-products-products-container-button"></button>
            </div>
          )}
          {counter == 7 && (
            <div
              className="all-products-product"
              style={{ visibility: "hidden" }}
            >
              <button className="all-products-products-container-button"></button>
            </div>
          )}
          {counter == 2 && (
            <div
              className="all-products-product"
              style={{ visibility: "hidden" }}
            >
              <button className="all-products-products-container-button"></button>
            </div>
          )}
          {counter == 2 && (
            <div
              className="all-products-product"
              style={{ visibility: "hidden" }}
            >
              <button className="all-products-products-container-button"></button>
            </div>
          )}
          {counter == 5 && (
            <div
              className="all-products-product"
              style={{ visibility: "hidden" }}
            >
              <button className="all-products-products-container-button"></button>
            </div>
          )}
          {counter == 5 && (
            <div
              className="all-products-product"
              style={{ visibility: "hidden" }}
            >
              <button className="all-products-products-container-button"></button>
            </div>
          )}
          {counter == 8 && (
            <div
              className="all-products-product"
              style={{ visibility: "hidden" }}
            >
              <button className="all-products-products-container-button"></button>
            </div>
          )}
          {counter == 8 && (
            <div
              className="all-products-product"
              style={{ visibility: "hidden" }}
            >
              <button className="all-products-products-container-button"></button>
            </div>
          )}
        </div>
        {page == 0 && currentProductType !== null && (
          <div className="all-products-page-change-button-row">
            <button
              className="all-products-button-number active"
              onClick={() => setPage(page)}
            >
              {page + 1}
            </button>
            {ProductsList.length > productsPerPage * (page + 1) && (
              <button
                className={
                  "all-products-button-number" +
                  (page * productsPerPage + productsPerPage <
                  ProductsList.length
                    ? ""
                    : " disabled")
                }
                onClick={() =>
                  page * productsPerPage + productsPerPage < ProductsList.length
                    ? setPage(page + 1)
                    : setPage(page)
                }
              >
                {page + 2}
              </button>
            )}
            {ProductsList.length > productsPerPage * (page + 2) && (
              <button
                className={
                  "all-products-button-number" +
                  (page * productsPerPage + productsPerPage * 2 <
                  ProductsList.length
                    ? ""
                    : " disabled")
                }
                onClick={() =>
                  page * productsPerPage + productsPerPage * 2 <
                  ProductsList.length
                    ? setPage(page + 2)
                    : setPage(page)
                }
              >
                {page + 3}
              </button>
            )}
            {ProductsList.length > productsPerPage * (page + 1) && (
              <button
                className={
                  "all-products-button-change" +
                  (page * productsPerPage + productsPerPage <
                  ProductsList.length
                    ? ""
                    : " disabled")
                }
                onClick={() =>
                  page * productsPerPage + productsPerPage < ProductsList.length
                    ? setPage(page + 1)
                    : setPage(page)
                }
              >
                Next
              </button>
            )}
          </div>
        )}
        {page != 0 && currentProductType !== null && (
          <div className="all-products-page-change-button-row">
            <button
              className="all-products-button-change"
              onClick={() => setPage(page - 1)}
            >
              Previous
            </button>
            <button
              className="all-products-button-number"
              onClick={() => setPage(page - 1)}
            >
              {page}
            </button>
            <button
              className="all-products-button-number active"
              onClick={() => setPage(page)}
            >
              {page + 1}
            </button>
            {ProductsList.length > productsPerPage * (page + 2) && (
              <button
                className={
                  "all-products-button-number" +
                  (page * productsPerPage + productsPerPage <
                  ProductsList.length
                    ? ""
                    : " disabled")
                }
                onClick={() =>
                  page * productsPerPage + productsPerPage < ProductsList.length
                    ? setPage(page + 1)
                    : setPage(page)
                }
              >
                {page + 2}
              </button>
            )}
            {ProductsList.length > productsPerPage * (page + 2) && (
              <button
                className={
                  "all-products-button-change" +
                  (page * productsPerPage + productsPerPage <
                  ProductsList.length
                    ? ""
                    : " disabled")
                }
                onClick={() =>
                  page * productsPerPage + productsPerPage < ProductsList.length
                    ? setPage(page + 1)
                    : setPage(page)
                }
              >
                Next
              </button>
            )}
          </div>
        )}
      </div>
      <div
        className="solutions-bottom-image-container"
        style={{ marginBottom: "70px" }}
      >
        <div className="solutions_stretched_container">
          <img
            src={HomaLighting}
            alt={`Living Room View`}
            className="solutions_img_stretched"
          />
        </div>
        <div style={{ position: "absolute", top: "0px", width: "100%" }}>
          <p className="solutions-bottom-image-top-text">SOLUTIONS</p>
          <p className="solutions-bottom-image-title">Homa Lighting</p>
          <p className="solutions-bottom-image-bottom-text">Coming Soon.</p>
        </div>
      </div>
      <CTAImage />
    </div>
  );
};

export default AllProductsPage;
