import React from "react";
import "../css/NewsPage.css";
import CTAImage from "../components/CTAImage";

const NewsPage = ({ title, text, date, author, image }) => {  
  const [width, setWidth] = React.useState(window.innerWidth);
  const newText = text?.split("/n").map((str) => <p>{str}</p>);

  React.useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }
  , []);

  /* make the text so that if it starts with a  ● then it has margin-left: 20px, it can also by accident have a space in front so make sure to check for that as well */
  let newTextArray = text.split("/n").map((str) => <p>{str}</p>);
  for (let i = 0; i < newTextArray.length; i++) {
    if (newTextArray[i].props.children[0] === "●") {
      newTextArray[i] = <p style={{marginLeft: "20px", marginBottom: "0px", marginTop: "0px"}}>{newTextArray[i].props.children}</p>;
    }
    if (newTextArray[i].props.children[0] === " ") {
      if (newTextArray[i].props.children[1] === "●") {
        newTextArray[i] = <p style={{marginLeft: "20px", marginBottom: "0px", marginTop: "0px"}}>{newTextArray[i].props.children}</p>;
      }
    }
    if (newTextArray[i].props.children[0] === "○") {
      newTextArray[i] = <p style={{marginLeft: "40px", marginBottom: "0px", marginTop: "0px"}}>{newTextArray[i].props.children}</p>;
    }
    if (newTextArray[i].props.children[0] === " ") {
      if (newTextArray[i].props.children[1] === "○") {
        newTextArray[i] = <p style={{marginLeft: "40px", marginBottom: "0px", marginTop: "0px"}}>{newTextArray[i].props.children}</p>;
      }
    }
    if (newTextArray[i].props.children[0] === "■") {
      newTextArray[i] = <p style={{marginLeft: "60px", marginBottom: "0px", marginTop: "0px"}}>{newTextArray[i].props.children}</p>;
    }
    if (newTextArray[i].props.children[0] === " ") {
      if (newTextArray[i].props.children[1] === "■") {
        newTextArray[i] = <p style={{marginLeft: "60px", marginBottom: "0px", marginTop: "0px"}}>{newTextArray[i].props.children}</p>;
      }
    }
  }
  

  return (
    <div className="news-entire-article">
      <div className="news-article-image-container">
      {image != null ? (<img src={image} alt="placeholder" className="news-article-image"/>) : null}
      {width < 1000 ? (
        <div className="news-article-info-container">
          <div className="news-article-info">
            <p className="news-article-info-toptext">
              Date
            </p>
            <p className="news-article-info-bottomtext">
              {date}
            </p>
          </div>
          <div>
            <p className="news-article-info-toptext">
              Written by
            </p>
            <p className="news-article-info-bottomtext">
              {author}
            </p>
          </div>
          <div>
            <p className="news-article-info-toptext">
              Source
            </p>
            <p className="news-article-info-bottomtext">
              Website
            </p>
          </div>
        </div>
        ) : null}
      </div>

      <div className="news-article-contents">
      {width > 1000 ? (
        <div className="news-article-info-container">
          <div className="news-article-info">
            <p className="news-article-info-toptext">
              Date
            </p>
            <p className="news-article-info-bottomtext">
             {date}
            </p>
          </div>
          <div>
            <p className="news-article-info-toptext">
              Written by
            </p>
            <p className="news-article-info-bottomtext">
              {author}
            </p>
          </div>
          <div>
            <p className="news-article-info-toptext">
              Source
            </p>
            <p className="news-article-info-bottomtext">
              Website
            </p>
          </div>
        </div>
        ) : null}
        <div>
          <p className="news-summary"></p>
          <p className="news-title">{title}</p>
          <img className="news-image" src={image} alt="placeholder" />
          <div className="news-text">
            {newTextArray}
          </div>
          <div className="news-next-button-container">
      <a className="news-next-button" href="../all-posts">
        <button>
        Next Article &nbsp; → 
        </button>
        </a>
      </div>     
        </div>   
       
      </div>
      
        
      

      <CTAImage />
    </div>
  );
};

export default NewsPage;
