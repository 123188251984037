import React from "react";
import { getStorage, ref, listAll, getDownloadURL } from "firebase/storage";
import { initializeApp } from "firebase/app";
import { getFirestore, collection, getDocs } from "firebase/firestore/lite";

import HomaLighting from "../imagesNew/Home/Homa Lighting - Desktop Section.png";

const firebaseConfig = {
  apiKey: "AIzaSyDqEyNt5rs8cke7JUpdZ1Vvept205vWCPc",
  authDomain: "smart-home-homa.firebaseapp.com",
  projectId: "smart-home-homa",
  storageBucket: "smart-home-homa.appspot.com",
  messagingSenderId: "474534868889",
  appId: "1:474534868889:web:3158e22d3d37221fa9c508",
  measurementId: "G-PR6SRFY2W5",
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

// Get a list of cities from your database
async function getProduct(db, slug) {
  const ProductsCol = collection(db, "products");
  const ProductsSnapshot = await getDocs(ProductsCol);
  const ProductsList = ProductsSnapshot.docs.map((doc) => doc.data());
  const Products = ProductsList.filter((product) => product.name == slug);
  if (Products.length == 0) {
    return {};
  }
  const Product = Products[0];
  return Product;
}

const ProductPage = () => {
  const [width, setWidth] = React.useState(window.innerWidth);
  const [finishedLoading, setFinishedLoading] = React.useState(false);
  const [activeImages, setActiveImages] = React.useState([]);
  const [product, setProduct] = React.useState([]);

  let slug = window.location.pathname.split("/")[2];

  let type = slug.split("%20")[0];
  let slugNotJoined = slug.split("%20").slice(1).join(" ");
  slug = slug.split("%20").slice(1).join("");
  if (slug.includes("%E2%80%A8")) {
    slug = slug.split("%E2%80%A8").
      join("");
    slugNotJoined = slugNotJoined.split("%E2%80%A8").
      join(" ");
  }

  if (type == "Door"){
    type = "Door Locks";
    slug = slug.split("Locks").join("");
  }

  React.useEffect(() => {
    getProduct(db, slugNotJoined).then((product) => {
      setProduct(product);
    });
  }, []);

  React.useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  React.useEffect(() => {
    const fetchImages = async () => {
      const storage = getStorage();
      const imagesFolder =
        width > 1600
          ? "Wide Screen"
          : width > 1200
          ? "Desktop"
          : width > 700
          ? "Tablet"
          : "Mobile";
      const imagesFolderRef = ref(
        storage,
        "Products/" + type + "/" + slug + "/" + imagesFolder
      );
      try {
        console.log("start of try");
        const result = await listAll(imagesFolderRef);
        const urlPromises = result.items.map((imageRef) =>
          getDownloadURL(imageRef)
        );

        const urls = await Promise.all(urlPromises);
        setActiveImages(urls);
        setFinishedLoading(true);
      } catch (error) {
        console.error("Error loading images", error);
      }
    };

    fetchImages();
  }, [width]);

  return (
    <div>

{finishedLoading ? (
    <div 
    /* if product is not empty, set the background color to the product's color */

    style={{
      backgroundColor: product.color ? "#" + product.color : "",
    }}>
      <div
        style={{
          width: "100vw",
          height: width > 1600 ? "1200px" : width > 1000 ? "800px" : "700px",
          overflow: "hidden",
          position: "relative",
          left: "50%",
          right: "50%",
          marginLeft: "-50vw",
          marginRight: "-50vw",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img
          src={width < 1600 ? activeImages[0] : activeImages[0]}
          alt="1"
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
            objectPosition: "center",
          }}
        />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          marginRight: width > 1000 ? "11%" : width > 700 ? "6%" : "7.5%",
          marginLeft: width > 1000 ? "11%" : width > 700 ? "6%" : "7.5%",
        }}
      >
        {/* make every image in active images excpet the first one into its own div */}
        {activeImages.slice(1).map((image, index) => {
          return (
            <div
              key={index}
              style={{
                width: "100%",
              }}
            >
              <img
                src={image}
                alt={index + 2}
                style={{
                  width: "100%",
                }}
              />
            </div>
          );
        })}
        <div
          className="cta-section-button"
          style={{
            marginTop: "50px",
          }}
        >
          <a href="../products-control">
            <button
            style={{
              color: product.color ? "#FFFFFF" : "",
            }}
            >See More &nbsp; → </button>
          </a>
        </div>
        <div
          className="solutions-bottom-image-container"
          style={{
            height: width > 1600 ? "1200px" : width > 1000 ? "800px" : "700px",
          }}
        >
          <div
            className="solutions_stretched_container"
            style={{
              height:
                width > 1600 ? "1200px" : width > 1000 ? "800px" : "700px",
            }}
          >
            <img
              src={HomaLighting}
              alt={`Living Room View`}
              className="solutions_img_stretched"
            />
          </div>
          <div style={{ position: "absolute", top: "0px", width: "100%" }}>
            <p className="solutions-bottom-image-top-text">SOLUTIONS</p>
            <p className="solutions-bottom-image-title">Homa Lighting</p>
            <p className="solutions-bottom-image-bottom-text">Coming Soon.</p>
          </div>
        </div>
      </div>
    </div>
    ) : (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <h1>Loading...</h1>
      </div>
    )}
  </div>
  );
};

export default ProductPage;
