import React, { useEffect, useState } from "react";
import "../css/SolutionsPage.css"; // Make sure to create this CSS file
import CTAImage from "../components/CTAImage";

import CompatabilityLogos from "../imagesNew/Solutions/compatabilityLogos.png";

import HomaControl from "../imagesNew/Solutions/Home Control - Desktop.png";
import HomaLighting from "../imagesNew/Home/Homa Lighting - Desktop Section.png";

import Curtains from "../imagesNew/Solutions/Curtains.png";
import Cameras from "../imagesNew/Solutions/Cameras.png";
import DoorLocks from "../imagesNew/Solutions/Door Locks.png";
import Hubs from "../imagesNew/Solutions/Hubs.png";
import Lighting from "../imagesNew/Solutions/Lighting.png";
import Sensors from "../imagesNew/Solutions/Sensors.png";
import Switches from "../imagesNew/Solutions/Switches.png";

import { FaAngleLeft, FaAngleRight } from "react-icons/fa";


function SolutionsPage() {
  const [width, setWidth] = useState(window.innerWidth);
  const [productTypeIndex, setProductTypeIndex] = useState(0);

  const productNames = [
    "Hubs",
    "Switches",
    "Sensors",
    "Door Locks",
    "Curtains",
    "Cameras",
    "Lighting",
  ];

  const productImages = [
    Hubs,
    Switches,
    Sensors,
    DoorLocks,
    Curtains,
    Cameras,
    Lighting,
  ];

  useEffect(() => {
    window.addEventListener("resize", () => setWidth(window.innerWidth));
  }, []);

  useEffect(() => {
    if (productTypeIndex < 0) {
      setProductTypeIndex(0);
    } else if (productTypeIndex > productNames.length - 3) {
      setProductTypeIndex(productNames.length - 3);
    }
  }
  , [productTypeIndex]);

  return (
    <div className="solutions-entire">
      <div className="solutions-video-container">
        <video
          className="solutions-video"
          autoPlay
          loop
          muted
          playsinline
          src={require("../imagesNew/Solutions/L1D0S132B0ENDPCWXFIQUWJ3MILUFX73YNNQ8_automatic.mp4")}
        ></video>
      </div>
      <div className="solutions-top-image-container">
      <div className="solutions_stretched_container">
        <img
            src={HomaControl}
            alt={`Living Room View`}
            className="solutions_img_stretched"
          />
        </div>
        <div style={{position: "absolute", top: "0px", maxWidth: "771px"}}>
        <p className="solutions-top-image-top-text">SOLUTIONS</p>
        <p className="solutions-top-image-title">Home Control</p>
        <p className="solutions-top-image-bottom-text">
          See how smart home automation and scene automation can make your life
          easier, more convenient, and more energy-efficient. Our team of
          experts will be on hand to answer your questions and help you create
          the perfect smart home system for your needs.
        </p>
        </div>

      </div>
      <div className="solutions-icons-container-entire">
        {width < 700 ? (
          <div className="solutions-icons-container">
            <div>
              <FaAngleLeft style={{ fontSize: "28px", marginRight: "10px", cursor: "pointer", color: "#ACACAC",  }} onClick={() => setProductTypeIndex(productTypeIndex - 1)} />
              </div>
            {productNames.slice(productTypeIndex, productTypeIndex + 3).map((productName, index) => (
              <div className="solutions-icon-container" onClick={() => window.location.href = `../products-control#${productName}`}>
                <img

                  src={productImages[index + productTypeIndex]}
                  className="solutions-icon-image"
                  alt={productName + "Icon"}
                />
                <p className="solutions-icon-text">{productName}</p>
              </div>
            ))}
            <div>
              <FaAngleRight style={{ fontSize: "28px", marginRight: "10px", cursor: "pointer", color: "#ACACAC", }} onClick={() => setProductTypeIndex(productTypeIndex + 1)} />
              </div>
          </div>
        ) : (
          <div className="solutions-icons-container">
            {productNames.map((productName, index) => (
              <div className="solutions-icon-container" onClick={() => window.location.href = `../products-control#${productName}`}>
                <img
                  src={productImages[index]}
                  className="solutions-icon-image"
                  alt={productName + "Icon"}
                />
                <p className="solutions-icon-text">{productName}</p>
              </div>
            ))}
          </div>
        )}
      </div>
      <div className="solutions-bottom-image-container">
      <div className="solutions_stretched_container">
        <img
            src={HomaLighting}
            alt={`Living Room View`}
            className="solutions_img_stretched"
          />
        </div>
      <div style={{position: "absolute", top: "0px", width: "100%", maxWidth: "771px"}}>
        <p className="solutions-bottom-image-top-text">SOLUTIONS</p>
        <p className="solutions-bottom-image-title">Homa Lighting</p>
        <p className="solutions-bottom-image-bottom-text">Coming Soon.</p>
      </div>
      </div>
      <div className="solutions-compatability-container">
        <p className="solutions-compatability-title">Wide Compatibility</p>
        <img
          src={CompatabilityLogos}
          className="solutions-compatability-image"
          alt="Solutions Header"
        />
      </div>
      <CTAImage />
    </div>
  );
}

export default SolutionsPage;
