import React, { useState, useEffect } from "react";
import { FaAngleDown } from "react-icons/fa";

const ImageTransition = () => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const [finishedScrolling, setFinishedScrolling] = useState(false);
  const [height, setHeight] = useState(
    window.document.documentElement.clientHeight
  );

  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  /* when setFinishedScrolling == true, make the user jump back to the top of the page */
  useEffect(() => {
    if (!finishedScrolling) {
      if (scrollPosition / window.document.documentElement.clientHeight > 1.5) {
        setFinishedScrolling(true);
        window.scrollTo(88, 0);
        playVid();
      }
    }
  }, [scrollPosition]);

  let vid = document.getElementsByClassName("home-video-unpaused")[0];

  function playVid() {
    vid.play();
  }

  function pauseVid() {
    vid.pause();
  }

  useEffect(() => {
    window.addEventListener("resize", () =>
      setHeight(window.document.documentElement.clientHeight)
    );
  }, []);

  return (
    <div style={{ position: "relative", overflow: "visible" }}>
      <div
        style={{
          position: "fixed",
          top: 0,
          width: "100vw",
          height: "100%",
          zIndex: 1,
          display: finishedScrolling ? "none" : "block",
          left: "50%",
          right: "50%",
          marginLeft: "-50vw",
          marginRight: "-50vw",
        }}
      >
        <div
          style={{
            height: height + "px",
            width: "100vw",
            opacity: 1,
            zIndex: 1,
            margin: "auto",
            objectFit: "cover",
            backgroundColor: "black",
          }}
        ></div>
        <div
          style={{
            zIndex: 2,
            opacity:
              0 + scrollPosition / window.document.documentElement.clientHeight,
            display: "flex",
            justifyContent: "center",
          }}
        >
          <video
            style={{
              marginTop: "88px",
              width: "100%",
              objectFit: "cover",
              objectPosition: "bottom",
              height: height - 88 + "px",
              position: "absolute",
              top: 0,
            }}
            className="home-video-paused"
            autoPlay
              loop
              muted
              playsinline
            src={require("../../src/imagesNew/Home/Homa Hero Banner.mp4")}
          ></video>
        </div>
        <p
          style={{
            position: "absolute",
            width: "100%",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            textAlign: "center",
            zIndex: 3,
            color: "white",
            fontSize: "54px",
            marginTop: "8px",
            lineHeight: "68px",
            opacity:
              1 - scrollPosition / window.document.documentElement.clientHeight,
          }}
        >
          Living Reimagined.
        </p>
        <p
          style={{
            position: "absolute",
            width: "100%",
            bottom: "60px",
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: 3,
            color: "white",
            fontSize: "14px",
            lineHeight: "21px",
            textAlign: "center",
            opacity:
              1 - scrollPosition / window.document.documentElement.clientHeight,
          }}
        >
          Scroll to enter the world of Smart Home Automation
        </p>
        <FaAngleDown
          style={{
            position: "absolute",
            bottom: "5px",
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: 3,
            color: "white",
            fontSize: "18px",
            backgroundColor: "#D9D9D9",
            borderRadius: "50%",
            padding: "10px",
            opacity:
              1 - scrollPosition / window.document.documentElement.clientHeight,
          }}
        />
      </div>

      <div
        style={{
          width: "100vw",
          visibility: finishedScrolling ? "visible" : "hidden",
          display: "flex",
          justifyContent: "center",
          position: "relative",
          left: "50%",
          right: "50%",
          marginLeft: "-50vw" /* Offset the container to the left */,
          marginRight: "-50vw" /* Offset the container to the right */,
          overflow: "hidden",
        }}
      >
        <video
          className="home-video-unpaused"
          style={{
            width: "100%",
            height: height - 88 + "px",
            objectFit: "cover",
            objectPosition: "bottom",
          }}
          autoPlay
              loop
              muted
              playsinline
          src={require("../../src/imagesNew/Home/Homa Hero Banner.mp4")}
        ></video>
      </div>
    </div>
  );
};

export default ImageTransition;
