import React from "react";
import CTAImageImg from "../imagesNew/Home/CTA Banner.jpeg";
import "../css/CTAImage.css";


const CTAImage = () => {
    

return (
<div className="cta-section">
          <img className="cta-section-img-rounded" src={CTAImageImg} alt="Image1" />
          <p className="cta-section-text">
            Come experience Smart Home Automation with us at our Experience
            Center
          </p>
          <div className="cta-section-button">
            <a href="contact-us#directionsSection">
              <button>Visit us today &nbsp; → </button>
            </a>
          </div>
        </div>
);

}

export default CTAImage;