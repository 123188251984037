import HeroImage from "../imagesNew/AboutUs/About Us Hero Banner.png";
import OurBackgroundImage from "../imagesNew/AboutUs/Our Background_Placeholder Image.png";
import OurMissionImage from "../imagesNew/AboutUs/Our Mission_Placeholder Image.png";
import TaglineImage1 from "../imagesNew/AboutUs/Tagline_Placeholder Image_01.png";
import TaglineImage2 from "../imagesNew/AboutUs/Tagline_Placeholder Image_02.png";

import "../css/AboutUs.css";

function AboutUs() {
  return (
    <div className="AboutUs">
      <div className="aboutus_hero_img_div">
        <img
          className="aboutus_hero_img"
          src={HeroImage}
        />
        <div className="aboutus_hero_text">
          <p>About Us</p>
        </div>
      </div>
      <div className="aboutus_body_text_div">
        <div className="aboutus_img_text_div1">
          <div className="aboutus_column">
        <p className="aboutus_body_top_text">Our Background</p>
        <p className="aboutus_body_text_section">
          For over two decades, our dedicated team has been at the forefront of
          the ever-evolving lighting industry. With a wealth of experience
          spanning 22 years, we have witnessed the remarkable journey from the
          warm glow of incandescent lights to the cutting-edge brilliance of LED
          technology.
        </p>
        <p className="aboutus_body_text_section">
          Recognizing the transformative power of automation, we have taken a
          bold step into the realm of smart home technology. At Homa, we are on
          a mission to empower families and individuals to create their ideal
          homes, where convenience and security harmonise seamlessly.
        </p>
        </div>
        <img
          className="aboutus_body_img_section"
          src={OurBackgroundImage}
        />
        </div>
        <div className="aboutus_img_text_div2">
        <div className="aboutus_column">
        <p className="aboutus_body_top_text">Our Mission</p>
        <p className="aboutus_body_text_section">
          Our commitment goes beyond mere lighting solutions. We understand that
          a home is more than just a physical space; it's a haven where memories
          are made, and lives unfold. With the rising popularity of automation,
          we have expanded our expertise to offer comprehensive smart home
          solutions.
        </p>
        <p className="aboutus_body_text_section">
          From the moment you step through the door to the time you draw the
          curtains for the night, Homa is there to enhance every aspect of your
          living experience.
        </p>
        </div>
        <img
          className="aboutus_body_img_section"
          src={OurMissionImage}
        />
        </div>
        <p className="aboutus_body_text_section">
          Our team's passion for innovation, coupled with our deep-rooted
          industry knowledge, position us as your trusted partner in
          transforming houses into intelligent, responsive, and secure homes. We
          believe that technology should seamlessly integrate into your
          lifestyle, enhancing it without complexity.
        </p>
        <p className="aboutus_body_text_section">
          Whether you are upgrading your lighting systems, automating doors and
          windows, or orchestrating the perfect ambiance with smart curtains,
          Homa is here to turn your vision into reality. Join us in this journey
          towards a brighter, smarter, and more connected future.
        </p>
        
        <div className="aboutus_body_text_images_div">
          <img
            className="aboutus_body_img_section1"
            src={TaglineImage1}
          />

          <img
            className="aboutus_body_img_section2"
            src={TaglineImage2}
          />

          <p className="aboutus_body_text_images_text">“Living Reimagined.”</p>
        </div>

        
      </div>
    </div>
  );
}

export default AboutUs;
