import React from "react";
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import GoogleMaps from "../components/GoogleMaps";
import FAQImage from "../imagesNew/ContactUs/FAQ Illustration.png";
import DirectionImage from "../imagesNew/ContactUs/Directions Image.png";

import {
  FaWhatsapp,
  FaInstagram,
  FaFacebookF,
  FaYoutube,
} from "react-icons/fa";

import { FaPlus, FaMinus } from "react-icons/fa";

import "../css/ContactUsPage.css";
import GoogleCalendarButton from "../components/GoogleCalendarButton";



function ContactUsPage() {
  const [expandedNumber, setExpandedNumber] = React.useState(0);
  const [windowWidth, setWindowWidth] = React.useState(window.innerWidth);
  const location = useLocation();

  useEffect(() => {
    // Check if there's a hash in the URL
    if (location.hash) {
      // Try to find the element
      const element = document.getElementById(location.hash.replace("#", ""));
      if (element) {
        // Scroll to the element but stop 30px before
        window.scrollTo({
          top: element.offsetTop - 95,
          behavior: "smooth",
        });
      }
    }
  }, [location]); // Run the effect when the location changes

  /* make function that if you setExpandedNumber to the same number it goes to 0 */
  function setExpandedNumberQuestion(number) {
    if (expandedNumber === number) {
      setExpandedNumber(0);
    } else {
      setExpandedNumber(number);
    }
  }

  React.useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  });

  function sendEmail(e) {
    e.preventDefault();

    const name = document.getElementById("name").value;
    const email = document.getElementById("email").value;
    const message = document.getElementById("message").value;

    /* send email with title "Hello from {name}" "Email: {email} Message: {message}" */
    window.open(
      `mailto:hello@homahub.com.sg?subject=Interest from ${name}&body=Email: ${email} Message: ${message}`
    );
  }

  return (
    <div className="ContactUs">
      <div className="contactus_form_map_div">
        <div className="contactus_form">
          <p className="contactus_body_text">CONTACT US</p>
          <p className="contactus_title_text">
            Have questions? 
            <br />
            Drop us a message
          </p>
          <form className="contactus_form_div">
            <div className="contactus_form_row">
              <div className="contactus_form_name">
                <label for="name" className="contactus_form_label">
                  YOUR NAME
                </label>
                <input
                  type="text"
                  className="contactus_form_input_small"
                  id="name"
                  name="name"
                />
              </div>
              <div className="contactus_form_email">
                <label for="email" className="contactus_form_label">
                  YOUR EMAIL
                </label>
                <input
                  type="email"
                  className="contactus_form_input_small"
                  id="email"
                  name="email"
                />
              </div>
            </div>

            <div className="contactus_form_message">
              <label for="message" className="contactus_form_label">
                MESSAGE
              </label>
              <textarea
                className="contactus_form_input_large"
                id="message"
                name="message"
              ></textarea>
            </div>

            <div className="contactus_form_buttons">
              <input
                className="contactus_form_submit"
                type="submit"
                value="Submit"
                onClick={sendEmail}
              />
              <button
                className="contactus_form_whatsapp"
                onClick={() =>
                  window.open("https://wa.me/message/7WKBLNPV3PPDL1")
                }
              >
                Chat with Us &nbsp; <FaWhatsapp />
              </button>
            </div>
          </form>
        </div>
        <div className="contactus_map">
          <GoogleMaps />
          <div className="contactus_map_texts">
            <div className="contactus_map_social_media">
              <p className="contactus_map_text_title">Social Media</p>
              <div className="contactus_map_social_media_icons">
                <a
                  href="https://www.instagram.com/homahubsg/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaInstagram />
                </a>
                <a
                  href="https://www.facebook.com/profile.php?id=61554961765127"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaFacebookF />
                </a>
                <a
                  href="https://www.youtube.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaYoutube />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="contactus_visit_us_div" id="directionsSection">
        <p className="contactus_visit_us_top_text">EXPERIENCE CENTER</p>
        <p className="contactus_visit_us_title_text">Come and Visit us!</p>
        <div className="contactus_visit_us_row">
          <div className="contactus_visit_us_column">
            <img className="contactus_visit_us_img" src={DirectionImage} />
            <p className="contactus_visit_us_img_text">
              * If you are driving, there are available carparks along Sims Ln
              as well as a multi-storey carpark at Victory Centre.
            </p>
          </div>

          <div className="contactus_visit_us_column">
            <p className="contactus_visit_us_get_there_top">HOW TO GET THERE</p>
            <p className="contactus_visit_us_get_there_title">Directions</p>
            {/* if window width more than 700px show a map */}
            {windowWidth > 700 && (
              <p className="contactus_visit_us_get_there_body">
                Our showroom is located near Aljunied MRT Station (EW9). If you
                are taking the MRT, you may exit the Station at Exit A. Once you
                are out of the station, head South on Aljunied Road and make a
                right turn towards Lor 23 Geylang. We are located on the left
                side of the road, about 160m away after making a right turn onto
                Lor 23 Geylang.
              </p>
            )}
            {windowWidth < 700 && (
              <div>
                <p className="contactus_visit_us_get_there_body">
                  Our showroom is located near Aljunied MRT Station (EW9). If
                  you are taking the MRT, you may exit the Station at Exit A.
                </p>
                <p className="contactus_visit_us_get_there_body">
                  Once you are out of the station, head South on Aljunied Road
                  and make a right turn towards Lor 23 Geylang.
                </p>
                <p className="contactus_visit_us_get_there_body">
                  We are located on the left side of the road, about 160m away
                  after making a right turn onto Lor 23 Geylang.
                </p>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="contactus_calendar_area">
        <p className="contactus_calendar_area_title">Book Your Appointment & Get Started Today</p>
        <p className="contactus_calendar_area_body">EXPERIENCE CENTER</p>
        <GoogleCalendarButton />
      </div>

      <div className="contactus_faq_div">
        <img className="contactus_faq_img" src={FAQImage} />
        <div>
          <p className="contactus_faq_title_text">Frequently asked questions</p>
          <p className="contactus_faq_body_text">
            Questions you might ask about our product and services.
          </p>
          <div className="contactus_faq_question_div_full_list">
            <div className="contactus_faq_question_div_full" onClick={() => {
                    setExpandedNumberQuestion(1);
                  }}>
              <div className="contactus_faq_question_div">
                <p className="contactus_faq_question_title">
                  What devices can I connect to your smart home system?
                </p>
                <button
                  className="contactus_faq_question_button"
                  onClick={() => {
                    setExpandedNumberQuestion(1);
                  }}
                >
                  {expandedNumber == 1 ? (
                    <FaMinus className="contactus_faq_question_minus" />
                  ) : (
                    <FaPlus className="contactus_faq_question_pluss" />
                  )}
                </button>
              </div>
              {expandedNumber == 1 && (
                <p className="contactus_faq_question_body">
                  Security is a top priority. Our smart home system offers
                  advanced security features, including real-time monitoring,
                  motion detection, and integration with smart door locks. You
                  can receive instant alerts and access a comprehensive security
                  dashboard through our user-friendly app, giving you peace of
                  mind whether you're at home or away.
                </p>
              )}
            </div>

            <div className="contactus_faq_question_div_full" onClick={() => {
                    setExpandedNumberQuestion(2);
                  }}>
              <div className="contactus_faq_question_div">
                <p className="contactus_faq_question_title">
                  Can I install the Smart Home system myself, or do I need
                  professional installation?
                </p>
                <button
                  className="contactus_faq_question_button"
                  onClick={() => {
                    setExpandedNumberQuestion(2);
                  }}
                >
                  {expandedNumber == 2 ? (
                    <FaMinus className="contactus_faq_question_minus" />
                  ) : (
                    <FaPlus className="contactus_faq_question_pluss" />
                  )}
                </button>
              </div>
              {expandedNumber == 2 && (
                <p className="contactus_faq_question_body">
                  Security is a top priority. Our smart home system offers
                  advanced security features, including real-time monitoring,
                  motion detection, and integration with smart door locks. You
                  can receive instant alerts and access a comprehensive security
                  dashboard through our user-friendly app, giving you peace of
                  mind whether you're at home or away.
                </p>
              )}
            </div>
            <div className="contactus_faq_question_div_full" onClick={() => {
                    setExpandedNumberQuestion(3);
                  }}>
              <div className="contactus_faq_question_div">
                <p className="contactus_faq_question_title">
                  How does the smart home system enhance security?
                </p>
                <button
                  className="contactus_faq_question_button"
                  onClick={() => {
                    setExpandedNumberQuestion(3);
                  }}
                >
                  {expandedNumber == 3 ? (
                    <FaMinus className="contactus_faq_question_minus" />
                  ) : (
                    <FaPlus className="contactus_faq_question_pluss" />
                  )}
                </button>
              </div>
              {expandedNumber == 3 && (
                <p className="contactus_faq_question_body">
                  Security is a top priority. Our smart home system offers
                  advanced security features, including real-time monitoring,
                  motion detection, and integration with smart door locks. You
                  can receive instant alerts and access a comprehensive security
                  dashboard through our user-friendly app, giving you peace of
                  mind whether you're at home or away.
                </p>
              )}
            </div>
            <div className="contactus_faq_question_div_full" onClick={() => {
                    setExpandedNumberQuestion(4);
                  }}>
              <div className="contactus_faq_question_div">
                <p className="contactus_faq_question_title">
                  Can I customize automation scenarios for different times of
                  the day?
                </p>
                <button
                  className="contactus_faq_question_button"
                  onClick={() => {
                    setExpandedNumberQuestion(4);
                  }}
                >
                  {expandedNumber == 4 ? (
                    <FaMinus className="contactus_faq_question_minus" />
                  ) : (
                    <FaPlus className="contactus_faq_question_pluss" />
                  )}
                </button>
              </div>
              {expandedNumber == 4 && (
                <p className="contactus_faq_question_body">
                  Security is a top priority. Our smart home system offers
                  advanced security features, including real-time monitoring,
                  motion detection, and integration with smart door locks. You
                  can receive instant alerts and access a comprehensive security
                  dashboard through our user-friendly app, giving you peace of
                  mind whether you're at home or away.
                </p>
              )}
            </div>
            <div className="contactus_faq_question_div_full" onClick={() => {
                    setExpandedNumberQuestion(5);
                  }}>
              <div className="contactus_faq_question_div">
                <p className="contactus_faq_question_title">
                  Can I control the smart home system when I'm away from home?
                </p>
                <button
                  className="contactus_faq_question_button"
                  onClick={() => {
                    setExpandedNumberQuestion(5);
                  }}
                >
                  {expandedNumber == 5 ? (
                    <FaMinus className="contactus_faq_question_minus" />
                  ) : (
                    <FaPlus className="contactus_faq_question_pluss" />
                  )}
                </button>
              </div>
              {expandedNumber == 5 && (
                <p className="contactus_faq_question_body">
                  Security is a top priority. Our smart home system offers
                  advanced security features, including real-time monitoring,
                  motion detection, and integration with smart door locks. You
                  can receive instant alerts and access a comprehensive security
                  dashboard through our user-friendly app, giving you peace of
                  mind whether you're at home or away.
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
      <div
        className="contactus_question_button_container"
      >
      <button
        className="contactus_question_button"
        onClick={() => window.open("https://wa.me/message/7WKBLNPV3PPDL1")}
      >
        Still have questions? &nbsp; →
      </button>
      </div>

    </div>
  );
}

export default ContactUsPage;
